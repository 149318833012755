<template>
<div class="recent-post">
  <div v-for="(post, i) in blog.bloglist.slice(-2).reverse()" class="post"  :key="i">
    <a class="post-img" :href="post.link">
      <img :src="post.poster" loading="lazy" :alt="post.title">
    </a>
    <div class="post-content">
      <a class="blog-list-title" :href="post.link">
        {{ post.title }}
      </a>
      <div class="post-descript">
        {{ post.desc }}
      </div>
      <div class="post-date">
        {{post.date}}
      </div>
    </div>
  </div>
</div>
</template>

<script>
import blog from '@/documents/blogList.js'

export default {
  data() {
    return {
      blog: blog,
    }
  },
}
</script>

<style lang="scss" scoped>
.recent-post {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 30px;

  .post {
    margin-left: 10px;
    padding: 20px 30px;
    box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    &:first-child {
      margin-left: 0;
      margin-right: 10px;
    }

    .post-img {
      img {
        height: 260px;
        object-fit: cover;
      }
    }
    .post-content {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      gap: 12px;
      .blog-list-title {
        font-size: 25px;
        font-weight: 600;
        color: #222;
        text-decoration: none;
        &:hover {
          color: #20a8ab;
        }
      }
      .post-descript {
        font-size: 16px;
        line-height: 1.55;
        color: #666;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .post-date {
        font-size: 14px;
        color: #666;
      }
    }
  }
}

@media (max-width: 768px) {
  .recent-post {
    grid-template-columns: 1fr;
    .post {
      margin-right: 0px !important;
      margin-left: 0px !important;
      margin-bottom: 20px;
      padding: 20px;
      .post-img {
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
