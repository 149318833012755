export default {
  bloglist: [
    {
      title: 'How to Transfer GB WhatsApp Messages to WhatsApp?',
      poster: require('@/assets/blog-1-small.webp'),
      desc: "If you are using WhatsApp and want to use the same account on a different phone, the answer is 'No' But if you are using GB WhatsApp and want to use the same account on a different phone, the answer is ",
      link: 'https://gbwhatsapks.net/how-to-backup-gbwhatsapp/',
      date: '2024/12/06'
    },
    {
      title: 'How to Download GB WhatsApp for PC Windows 10',
      poster: require('@/assets/How-to-download-gb-whatsapp-for-pc-windows-10-small.webp'),
      desc: "GB WhatsApp is a popular modified version of WhatsApp that offers several enhanced features not found in the official app. The GB WhatsApp PC version offers more features, enhanced customization and improved privacy options compared to the standard WhatsApp, but How to download gb whatsapp for pc windows 10?",
      link: 'https://gbwhatsapks.net/how-to-download-gb-whatsapp-pc-windows-10/',
      date: '2025/03/12'
    },
    {
      title: 'Why 50 Million Users Prefer GB WhatsApp? A Detailed Feature Comparison',
      poster: require('@/assets/50-million-users-prefer-gb-whatsapp-x1.webp'),
      desc: "It's true that GB WhatsApp has gained significant popularity, with millions of users worldwide preferring it over the official WhatsApp. The 50 million figure is an estimate based on downloads from unofficial sources since GB WhatsApp is not available on Google Play or the App Store.",
      link: 'https://gbwhatsapks.net/why-50-million-useers-prefer-gb-whatsapp-feature-comparison/',
      date: '2025/03/14'
    },
    {
      title: 'How to Download GBWhatsApp APK Safely: A Step-by-Step Guide',
      poster: require('@/assets/download-gbwhatsapp-apk-safely-x1.webp'),
      desc: "GBWhatsApp is a popular modified version of WhatsApp that offers advanced features such as hiding online status, custom themes, and enhanced privacy settings. However, since it's not available on the Google Play Store, users need to manually download and install the GBWhatsApp APK.",
      link: 'https://gbwhatsapks.net/how-to-download-gbwhatsapp-apk-safely/',
      date: '2025/03/18'
    },
    {
      title: 'What is GB WhatsApp? Key Features of GB WhatsApp You Want to Know',
      poster: require('@/assets/key-feature-gb-whatsapp-x1.webp'),
      desc: "GB WhatsApp is a third-party modified version of the official WhatsApp app. It offers additional features and customization options that are not available in the official version.",
      link: 'https://gbwhatsapks.net/how-to-download-gbwhatsapp-by-steps/',
      date: '2025/03/21'
    },
    {
      title: 'How often does GBWhatsApp update its version?',
      poster: require('@/assets/gbwhatsupdate.webp'),
      desc: "GBWhatsApp updates are released irregularly. While there is no fixed timeline, updates typically occur every few months, depending on various factors such as WhatsApp’s official updates.",
      link: 'https://gbwhatsapks.net/how-often-does-gbwhatsapp-update/',
      date: '2025/03/25'
    },
    {
      title: 'Can You Use GBWhatsApp and WhatsApp on the Same Phone?',
      poster: require('@/assets/gbwhatsapp-Phone.webp'),
      desc: "While using both GBWhatsApp and WhatsApp on the same phone offers more features and flexibility，Please download GB WhatsApp on the source safely.",
      link: 'https://gbwhatsapks.net/use-gbwhatsapp-and-whatsapp-on-the-same-phone/',
      date: '2025/03/28'
    },
    {
      title: 'How to Disable Double Tick in GB WhatsApp?',
      poster: require('@/assets/how-to-disable-double-tick-in-gb-whatsapps.webp'),
      desc: "One of GB WhatsApp features is the ability to disable the double tick, In this guide, we will walk you through the steps to disable the double tick in GB WhatsApp.",
      link: 'https://gbwhatsapks.net/how-to-disable-double-tick-in-gb-whatsapp/',
      date: '2025/04/03'
    },
  ],
}
