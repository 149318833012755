import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeM from '../views/gb/mobile.vue'
import Home from '../views/home.vue'
// import UAParser from 'ua-parser-js';
// const uaParser = new UAParser();
// const deviceInfo = uaParser.getResult();

// const isMobileDevice = deviceInfo.device.type === 'mobile';
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		name: 'en-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp Download 2025|GBWhatsApp APK Update Latest Version`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GB WhatsApp provides extra features than the original WhatsApp. What's new in the latest update GBWhatsApp APK? Visit our site to learn and download gbwhatsapp.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/'
				}
			],
			// breadcrumb: [
			// 	{
			// 		text: 'Home',
			// 	},
			// ],
		},
		component: Home,
	},
	{
		path: '/new',
		name: 'Homenew',
		meta: {
			appname: 'GBWhatsApp',
		},
		component: Home,
	},
	{
		path: '/download-gbwhatsapp',
		name: 'download',
		meta: {
			appname: 'GBWhatsApp',
			title: `Download GBWhatsApp Latest Version-Secure & Free`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Get the latest version of GBWhatsApp for Android. Enjoy enhanced privacy, customization, and exclusive features. Safe and secure download.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/download-gbwhatsapp/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{
					text: 'Download',
				},
			],
		},
		component: () => import('@/views/download.vue'),
	},
	{
		path: '/gb-whatsapp-old-versions',
		name: 'old-version',
		meta: {
			appname: 'GBWhatsApp',
			title: `Download GB WhatsApp Old Versions | Latest 2024 to 2025 APKs`,
			keywords: 'GB WhatsApp old version,gb whatsapp download apk old versions',
			description: `Download GB WhatsApp old versions (2024-2025) APKs for Android. Get the latest and previous GB WhatsApp updates with enhanced features, privacy options, and customization.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/gb-whatsapp-old-versions/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{
					text: 'Old Versions',
				},
			],
		},
		component: () => import('@/views/old-version.vue'),
	},
	{
		path: '/blogs',
		name: 'blogs',
		meta: {
			appname: 'GBWhatsApp',
			title: ` GB WhatsApp Blogs`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Blogs about GBWhatsApp news, features, backup&restore. Learn more details about GB WhatsApp.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/blogs/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{
					text: 'Blogs',
				},
			],
		},
		component: () => import('@/views/blogs.vue'),
	},
	{
		path: '/how-to-backup-gbwhatsapp',
		name: 'blog1',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Transfer GBWhatsApp Chats to WhatsApp Completely`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `To transition from GB WhatsApp to WhatsApp, you may need to transfer chats history to WhatsApp. This blog introduces the methods.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/how-to-backup-gbwhatsapp/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{ text: 'Blogs', to:'https://gbwhatsapks.net/blogs/' },
				{
					text: 'How to Transfer GBWhatsApp Chats to WhatsApp Completely',
				},
			],
		},
		component: () => import('@/views/blog1.vue'),
	},
	{
		path: '/how-to-download-gb-whatsapp-pc-windows-10',
		name: 'blog2',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to download gb whatsapp for pc windows 10`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `To download GB WhatsApp for PC (Windows 10), follow these steps. Note that GB WhatsApp is an Android-only modified app, so it requires an Android emulator to run on Windows.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/how-to-download-gb-whatsapp-pc-windows-10/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{ text: 'Blogs', to:'https://gbwhatsapks.net/blogs/' },
				{
					text: 'How to Download GB WhatsApp for PC Windows 10',
				},
			],
		},
		component: () => import('@/views/blog2.vue'),
	},
	{
		path: '/why-50-million-useers-prefer-gb-whatsapp-feature-comparison',
		name: 'blog3',
		meta: {
			appname: 'GBWhatsApp',
			title: `Why 50 Million Users Prefer GB WhatsApp? A Detailed Feature Comparison`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Why 50 Million Users Prefer GB WhatsApp? Many users prefer GBWhatsApp over the official WhatsApp because it offers advanced features and more customization options.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/why-50-million-useers-prefer-gb-whatsapp-feature-comparison/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{ text: 'Blogs', to:'https://gbwhatsapks.net/blogs/' },
				{
					text: 'Why 50 Million Users Prefer GB WhatsApp? A Detailed Feature Comparison',
				},
			],
		},
		component: () => import('@/views/blog3.vue'),
	},
	{
		path: '/how-to-download-gbwhatsapp-apk-safely',
		name: 'blog4',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Download GBWhatsApp APK Safely: A Step-by-Step Guide`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `How to Download GBWhatsApp APK Safely? In this guide, we'll walk you through the safe and easy way to download GBWhatsApp without any risks.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/how-to-download-gbwhatsapp-apk-safely/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{ text: 'Blogs', to:'https://gbwhatsapks.net/blogs/' },
				{
					text: 'How to Download GBWhatsApp APK Safely: A Step-by-Step Guide',
				},
			],
		},
		component: () => import('@/views/blog4.vue'),
	},
	{
		path: '/how-to-download-gbwhatsapp-by-steps',
		name: 'blog5',
		meta: {
			appname: 'GBWhatsApp',
			title: `What is GB WhatsApp? Key Features of GB WhatsApp You Want to Know`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GB WhatsApp is a third-party modified version of the official WhatsApp app. It offers additional features and customization options that are not available in the official version.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/how-to-download-gbwhatsapp-by-steps/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{ text: 'Blogs', to:'https://gbwhatsapks.net/blogs/' },
				{
					text: 'What is GB WhatsApp? Key Features of GB WhatsApp You Want to Know',
				},
			],
		},
		component: () => import('@/views/blog5.vue'),
	},
	{
		path: '/how-often-does-gbwhatsapp-update',
		name: 'blog6',
		meta: {
			appname: 'GBWhatsApp',
			title: `How often does GBWhatsApp update its version?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GBWhatsApp updates are released irregularly. While there is no fixed timeline, updates typically occur every few months, depending on various factors such as WhatsApp’s official updates.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/how-often-does-gbwhatsapp-update/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{ text: 'Blogs', to:'https://gbwhatsapks.net/blogs/' },
				{
					text: 'How Often Does GBWhatsApp Update Its Version?',
				},
			],
		},
		component: () => import('@/views/blog6.vue'),
	},
	{
		path: '/use-gbwhatsapp-and-whatsapp-on-the-same-phone',
		name: 'blog7',
		meta: {
			appname: 'GBWhatsApp',
			title: `Can You Use GBWhatsApp and WhatsApp on the Same Phone?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: ` While using both GBWhatsApp and WhatsApp on the same phone offers more features and flexibility，Please download GB WhatsApp on the source safely.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/use-gbwhatsapp-and-whatsapp-on-the-same-phone/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{ text: 'Blogs', to:'https://gbwhatsapks.net/blogs/' },
				{
					text: 'Can You Use GBWhatsApp and WhatsApp on the Same Phone?',
				},
			],
		},
		component: () => import('@/views/blog7.vue'),
	},
	{
		path: '/how-to-disable-double-tick-in-gb-whatsapp',
		name: 'blog8',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Disable Double Tick in GB WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `One of GB WhatsApp features is the ability to disable the double tick, In this guide, we will walk you through the steps to disable the double tick in GB WhatsApp.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/how-to-disable-double-tick-in-gb-whatsapp/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },
				{ text: 'Blogs', to:'https://gbwhatsapks.net/blogs/' },
				{
					text: 'How to Disable Double Tick in GB WhatsApp?',
				},
			],
		},
		component: () => import('@/views/blog8.vue'),
	},
	{
		path: '/about-us',
		name: 'about-us',
		meta: {
			appname: 'GBWhatsApp',
			title: `About Us`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `This page contains the information about who we are and why we build this site for GBWhatsApp.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/about-us/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },

				{
					text: 'About Us',
				},
			],
		},
		component: () => import('@/views/about.vue'),
	},
	{
		path: '/disclaimer',
		name: 'disclaimer',
		meta: {
			appname: 'GBWhatsApp',
			title: `Disclaimer`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Read the disclaimer of this site. Any actions you take based on the information are strictly at your own risk.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/disclaimer/'
				}
			],
			breadcrumb: [
				{ text: 'Home', to:'https://gbwhatsapks.net/' },

				{
					text: 'Disclaimer',
				},
			],
		},
		component: () => import('@/views/disclaimer.vue'),
	},
	{
		path: '/transfer',
		name: 'transfer',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatsapks.net/transfer/'
				}
			]
		},
		component: () => import('@/views/transfer.vue'),
	},
	{
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  }
]

// export const constRoutesM = [
// 	{
// 		path: '/',
// 		name: 'Home',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: true,
// 			showTelegram: true,
// 		},
// 		// component: HomeM,
// 	},
// 	{
// 		path: '/download',
// 		name: 'download',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: false,
// 		},
// 		component: () => import('@/components/download.vue'),
// 	},
// ]

let routes = []
// if (isMobileDevice) {
// 	routes = constRoutesM
// }
// if (!isMobileDevice) {
// 	routes = constRoutesPc
// }

routes = constRoutesPc
export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
